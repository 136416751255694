/**
 * 封装树形结构
 * @param roleArr 【[[2,8],[2,9]]】
 * @param resultArr 返回的结果
 */
export function encapsulationMenus(roleArr, resultArr) {
  if (!roleArr || roleArr.length === 0) {
    return resultArr;
  }
  let oneStr = ":";
  roleArr.map(item => {
    // 下层还有数组
    if (item instanceof Array) {
      encapsulationMenus(item, resultArr);
    } else {
      oneStr += item + ":";
    }
  });
  if (oneStr !== ":") {
    resultArr.push(oneStr);
  }
  return resultArr;
}
/**
 * 拆分树形结构
 * @param roleStr 【[":2:9:12:"]】
 */
export function splitMenus(roleStr) {
  const resultArr = [];
  if (!roleStr) {
    return resultArr;
  }
  JSON.parse(roleStr).map(item => {
    const arr = [];
    const strArr = item.split(":");
    // 多级
    if (strArr instanceof Array) {
      strArr.map(o => {
        if (o) {
          arr.push(parseInt(o, 10));
        }
      });
    } else {
      arr.push(parseInt(item, 10));
    }
    resultArr.push(arr);
  });
  return resultArr;
}

export function formatDateTime(dateTime) {
  // return moment(dateTime).format('YYYY-MM-DD HH:mm:ss');
  if (dateTime === null || dateTime === undefined) {
    return "";
  }
  const d = new Date(dateTime);
  d.setTime(d.getTime() - d.getTimezoneOffset() * 60 * 1000);
  const aa = d.toISOString();
  return aa.slice(0, 19).replace("T", " ");
}

export function formatApiDate(dateTime) {
  if (dateTime === null || dateTime === undefined) {
    return "";
  }
  if (typeof dateTime === "number") {
    return formatDateTime(dateTime);
  } else {
    return dateTime.slice(0, 19).replace("T", " ");
  }
}

/**
 * 获取文件
 */
export function getFileName(path) {
  let fileName = "";
  if (!path) {
    return fileName;
  }
  const pathArr = path.split("/");
  fileName = pathArr[pathArr.length - 1];
  return fileName;
}

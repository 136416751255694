import request from "@/utils/request";

/**
 * 创建用户【OK】
 * @param params
 * @returns {*}
 */
export function createUser(params) {
  return request({
    url: "/api/backend/account/admin/create",
    method: "post",
    data: params
  });
}
/**
 * 编辑用户【OK】
 * @param params
 * @returns {*}
 */
export function updateUser(params) {
  return request({
    url: "/api/backend/account/admin/update",
    method: "post",
    data: params
  });
}

/**
 * 重置密码【OK】
 * @param params
 * @returns {*}
 */
export function resetPassword(params) {
  return request({
    url: "/api/backend/account/admin/resetPassword",
    method: "post",
    data: params
  });
}

/**
 * 查询账号信息[ok]
 * @param params
 * @returns {*}
 */
export function getBackGroundUserList(params) {
  return request({
    url: "/api/backend/account/admin/getList",
    method: "get",
    params
  });
}

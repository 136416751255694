// ================  公共 start ======================================================================

/**
 * 根据key获取value
 * @param optionArr
 * @param key
 */
export function getValue(optionArr, key) {
  let resultVal = "";
  if (!optionArr || optionArr.length === 0) {
    return resultVal;
  }
  optionArr.map(item => {
    if (item.key === key) {
      resultVal = item.value;
    }
  });
  return resultVal;
}

/**
 * 获取短信的类型 register-注册，login-登录，changepassword-修改密码
 * @type {{}}
 */
export const SMS_TYPE = {
  REGISTER: "register",
  LOGIN: "login",
  CHANGEPASSWORD: "changepassword"
};
/**
 * 上传图片的类型 common-公共,avatar-头像,artgoods-艺术品,news-新闻
 * @type {{}}
 */
export const UPLOAD_IMG_TYPE = {
  COMMON: "common",
  AVATAR: "avatar",
  ARTGOODS: "artgoods",
  NEWS: "news"
};

/**
 * 弹框的标题
 * @type {{CREATE: string, UPDATE: string}}
 */
export const DIALOG_TITLE_TYPE = {
  UPDATE: "编辑",
  CREATE: "添加"
};

/**
 * 附件类型 0-没有，1-视频，2-文件，3-艺术品，4-艺术家
 * @type {[{value: string, key: number},{value: string, key: number}]}
 */
export const ATT_TYPE = [
  {
    key: 0,
    value: "没有"
  },
  {
    key: 1,
    value: "视频"
  },
  {
    key: 2,
    value: "文件"
  }
  // {
  //   key: 3,
  //   value: "艺术品"
  // },
  // {
  //   key: 4,
  //   value: "艺术家"
  // }
];
// ================  公共 end ======================================================================

// ================  系统设置 start ======================================================================

/**
 * 用户的状态
 * @type {[{value: string, key: number},{value: string, key: number}]}
 */
export const IS_FORBIDDEN = [
  {
    key: 0,
    value: "正常"
  },
  {
    key: 1,
    value: "冻结"
  }
];
/**
 * 菜单类型
 * @type {[{value: string, key: string},{value: string, key: string}]}
 */
export const MENU_TYPE = [
  {
    key: "MENU",
    value: "菜单"
  },
  {
    key: "ACTION",
    value: "操作"
  }
];

// ================  系统设置 end ======================================================================

// ================  艺术品 start ======================================================================
/**
 * 性别
 * @type {{W: string, M: string}}
 */
export const GENDER = [
  {
    key: "男",
    value: "男"
  },
  {
    key: "女",
    value: "女"
  }
];
/**
 * 收藏家的类型
 * @type {[{value: string, key: string},{value: string, key: string}]}
 */
export const COLLECTOR_TYPE = [
  {
    key: 0,
    value: "个人"
  },
  {
    key: 1,
    value: "机构"
  }
];
// ================  艺术品 end ======================================================================

// ================  新闻中心 start ======================================================================

/**
 * 新闻类型 1-集团新闻，2-公示公告，3-行业动态，4-媒体报道，5-视频资料
 * @type {[{value: string, key: number},{value: string, key: number}]}
 */
export const NEWS_CENTER_TYPE = [
  {
    key: 1,
    value: "集团新闻"
  },
  {
    key: 2,
    value: "公示公告"
  },
  {
    key: 3,
    value: "行业动态"
  },
  {
    key: 4,
    value: "媒体报道"
  },
  {
    key: 5,
    value: "视频资料"
  },
  {
    key: 6,
    value: "文件资料"
  }
];

// ================  新闻中心 end ======================================================================
